<template>
  <div class="home-w">
    <div class="home-w-cont">
      <p>一年之计在于春，一日之计在于寅。</p>
      <p>一家之计在于和，一生之计在于勤。</p>
    </div>
    <div class="home-w-btm">
      <div class="home-w-btm-w">
        <!-- 工信备案 -->
        <a :href="gxba.url" target="_blank" style="margin-right: 20px">
          <p>{{ gxba.title }}</p>
        </a>

        <!-- 公安备案 -->
        <a :href="gaba.url" target="_blank">
          <img src="@/assets/gaba.png" style="float: left" />
          <p>{{ gaba.title }}</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      gxba: {
        url: "https://beian.miit.gov.cn/",
        title: "ICP备案/许可证号：浙ICP备2023026526号-1",
      },
      gaba: {
        url: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010202002737",
        title: "浙公网安备 33010202002737号",
      },
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.home-w {
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
  border-radius: 16px;
  position: relative;
  padding-top: 10%;
  &-cont {
    width: 50%;
    margin: 0 auto;
    padding: 20px 0;
    border-radius: 16px;
    box-shadow: 0px 1px 1px 1px #b8b8bd;
    font-size: 24px;
  }
  &-btm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    &-w {
      margin: 0 auto;
      a {
        display: inline-block;
        text-decoration: none;
        height: 20px;
        line-height: 20px;
      }
      p {
        float: left;
        height: 20px;
        line-height: 20px;
        margin: 0px 0px 0px 5px;
        color: #939393;
      }
    }
  }
}
</style>
